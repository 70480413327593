.spotify-controls {
    background-color: black;
    color: white;
    height: max-content;
    scroll-behavior: unset;
    display: flex;
  }
  
  .spotify-controls > img {
    max-width: 100%;
    width: auto\9; /* ie8 */
    border-radius: 25px;
  }
  
  .playing-track {
    margin: auto;
  }

/** Progress **/
.progress {
    border: 1px solid #eee;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
}
  
.progress-free {
    border: 1px solid #eee;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
    width: 200px;
}
  
.progress__bar {
    background-color: #eee;
    color:darkgreen;
    height: 4px;
}
  
.logo {
    position:relative;
    cursor: pointer;
}