.chord-progressions {
    display: grid;

    padding-left: 15%;
    position:relative;
    left: 10%;
    right:10%;
    height:max-content;

}

table, th, td {
    border: 1px solid black;
    padding: 5px;
}

table {
    border-spacing: 15px;
    width: 100%;
    height: fit-content;
}

.no-progressions {
    display: grid;
    place-items: center;
    color:darkgray;
    font-style: italic;
    padding-right: 25%;
}