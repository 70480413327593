.chords {
    /* margin:auto; */
    height:max-content;
}

table, th, td {
    border: 1px solid black;
    padding: 5px;
}

table {
    border-spacing: 15px;
    width: 100%;
}

.no-chords {
    display: grid;
    place-items: center;
    color:darkgray;
    font-style: italic;
    padding-right: 25%;
}