html, body {margin: 0; height: 100%}

.app {
  text-align: center;
}

a {
  padding-top: 0.75em;
    padding-right: 1em;
    padding-bottom: 0.75em;
    padding-left: 1em
}


.buttons {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border: 2px solid gray;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px; 
}

.buttons:hover {
  font-size: 18px;
  border: 2px solid green;
  border-radius: 100px;
  width: 100px;
  height: 100px;
}

.buttons:target {
  display: none;
  font-size: 18px;
  border: 2px solid red;
  border-radius: 100px;
  width: 100px;
  height: 100px;
}

.corkboard {
  height: max-content;
  color: black;
  overflow: visible;

  display: grid;
  grid-template-columns: auto auto auto;
}

.login {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: black;
  color:white;
}

.login > p {
  font-size: xx-large;
  font-family: sans-serif;
  font-weight:bolder;
}

.login > img {
  width: auto;
  height: 150px;
}

.login > a {
  padding: 20px;
  border-radius: 99px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  background-color: #1db954;
}

.album-art {
  display: grid;
}




/* div {
  max-width: 100%;
  height: auto;
  width: auto\9;
} */