.rhythm {
    /* display: grid; */
    /* padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 15%;
    padding-right: 15px; */

    margin: auto;
    padding-right: 25%;
}

.rhythm-child-1 {
    flex: 1;
}
.rhythm-child-2 {
    flex: 1;
    padding-top:10%;
}

.no-rhythm {
    display: grid;
    place-items: center;
    color:darkgray;
    font-style: italic;
    padding-right: 25%;
}