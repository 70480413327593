.fretboard {
    /* position: relative; */
    /* padding-top: 15px; */
    /* padding-bottom: 15px; */
    /* padding-inline-end: 50%; */
    overflow: hidden;
    /* margin:auto; */
    display: grid;
    place-items: center;
}

.fretboard > .relative-1 > img {
    max-width: 100%;
    height: auto;
    width: auto\9;
}

.fretboard > .relative-2 > img {
    max-width: 100%;
    height: auto;
    width: auto\9;
}

.relative-1 {
    /* position:relative; */
    flex: 1;
}

.relative-2 {
    position:relative;
    margin-bottom: 10px;
}

.no-fretboard {
    display: grid;
    place-items: center;
    color:darkgray;
    font-style: italic;
    padding-right: 25%;
}